<div class="modal-header">
  <h4 class="modal-title" translate>EVENT.EDIT_DATES</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="editEventDateForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-warning">
          <p translate>EVENT.DATES_WARNING</p>
          <p><strong translate>EVENT.DAYS_COUNT</strong>: {{ eventDaysCount }}</p>
          <p translate>EVENT.DAYS_COUNT_INFO</p>
        </div>
      </div>
      
      <!-- Lista de seletores de data para cada dia do evento -->
      <div class="col-md-12 mb-3">
        <h5 translate>EVENT.SELECT_DATES</h5>
      </div>
      
      <div class="col-md-12" formArrayName="eventDates">
        <div 
          *ngFor="let dateControl of eventDatesFormArray.controls; let i = index" 
          class="form-group row mb-3"
          [formGroupName]="i"
        >
          <div class="col-md-3">
            <label [for]="'eventDay' + i" class="col-form-label">
              <span translate>EVENT.DAY</span> {{ i + 1 }}:
            </label>
          </div>
          <div class="col-md-6">
            <input
              type="date"
              [id]="'eventDay' + i"
              formControlName="date"
              class="form-control"
            />
          </div>
          <div class="col-md-12" *ngIf="submitted && dateControl.get('date').invalid">
            <div class="alert alert-danger mt-2">
              <small 
                *ngIf="dateControl.get('date').errors?.required" 
                class="form-text text-danger"
                translate
              >
                DATE_REQUIRED
              </small>
              <small 
                *ngIf="dateControl.get('date').errors?.pastDate" 
                class="form-text text-danger"
                translate
              >
                DATE_CANNOT_BE_IN_PAST
              </small>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-12" *ngIf="submitted && editEventDateForm.errors">
        <div class="alert alert-danger">
          <small *ngIf="editEventDateForm.errors?.datesNotSequential" translate>
            DATES_MUST_BE_SEQUENTIAL
          </small>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')"
      translate
    >
      CLOSE
    </button>
    <button
      type="submit"
      class="btn btn-sm-block btn-gradient-primary mr-2 float-right"
      translate
    >
      SAVE
    </button>
  </div>
</form>
