import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { Event } from "../dto/Event";
import { EventUser } from "../dto/EventUser";
import { AddCategory } from "../dto/AddCategory";
import { TakeOff } from "../dto/TakeOff";
import { HttpRequest } from "@angular/common/http";
import { Animal } from "../dto/Animal";
import { Competitor } from "../dto/Competitor";
import { EditEvent } from "../dto/EditEvent";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(private http: HttpClient) {}

  getEventsNoTypeGroup() {
    return this.http
      .get(`${environment.apiUrl}/events/notypeGroup`)
      .toPromise();
  }

  addEvent(event: Event) {
    return this.http.post(`${environment.apiUrl}/events`, event).toPromise();
  }

  updateEventBasics(eventId: String, event: EditEvent) {
    return this.http
      .put(`${environment.apiUrl}/events/${eventId}/basic`, event)
      .toPromise();
  }

  updateEventDescription(eventId: String, description: String) {
    return this.http
      .put(`${environment.apiUrl}/event/${eventId}/description`, {
        description,
      })
      .toPromise();
  }

  updateEventInfo(eventId: String, infos: String) {
    return this.http
      .put(`${environment.apiUrl}/event/${eventId}/infos`, { infos })
      .toPromise();
  }

  updateEventGeneral(
    eventId: string,
    arenaRepairInterval: number,
    generalRepairInterval: number
  ) {
    return this.http
      .put(`${environment.apiUrl}/event/${eventId}/general`, {
        arenaRepairInterval,
        generalRepairInterval,
      })
      .toPromise();
  }

  updateEventDates(eventId: string, startDate: number, finalDate: number, eventDates?: number[]) {
    return this.http
      .put(`${environment.apiUrl}/event/${eventId}/dates`, {
        startDate,
        finalDate,
        eventDates
      })
      .toPromise();
  }

  uploadEventImage(img) {
    const req = new HttpRequest(
      "POST",
      `${environment.apiUrl}/events/upload`,
      img,
      {
        // headers: You can put your headers type hear such as content/type, token...
        reportProgress: true,
      }
    );

    return this.http.request(req);
  }

  getEvent(slug) {
    return this.http.get(`${environment.apiUrl}/events/${slug}`).toPromise();
  }

  getEventById(id: string) {
    return this.http
      .get(`${environment.apiUrl}/events/eventbyid/${id}`)
      .toPromise();
  }

  getEventOrderList(eventId) {
    return this.http
      .get(`${environment.apiUrl}/events/${eventId}/orderlist`)
      .toPromise();
  }

  addEventUser(eventUser: EventUser, eventId: String) {
    return this.http
      .post(`${environment.apiUrl}/events/${eventId}/users`, eventUser)
      .toPromise();
  }

  getEventUsers(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/event/${eventId}/users`)
      .toPromise();
  }

  getEventUserRoles(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/event/${eventId}/user/permissions`)
      .toPromise();
  }

  searchCategory(query: String) {
    return this.http.post(`${environment.apiUrl}/category/search`, {
      searchString: query,
    });
  }

  searchCompetitor(query: String) {
    return this.http.post(`${environment.apiUrl}/competitor/search`, {
      searchString: query,
      useLocal: true,
    });
  }

  searchHorse(query: String) {
    return this.http.post(`${environment.apiUrl}/horse/search`, {
      searchString: query,
      useLocal: true,
    });
  }

  searchCustomer(query: String) {
    return this.http.post(`${environment.apiUrl}/customer/search`, {
      searchString: query,
    });
  }

  advancedSearchCustomer(query: String) {
    return this.http.post(`${environment.apiUrl}/customer/advancedsearch`, {
      searchString: query,
    });
  }

  getAllCustomers(page: number) {
    return this.http
      .get(`${environment.apiUrl}/customers?page=${page}`)
      .toPromise();
  }

  deleteEventUser(eventId: String, userId: String) {
    return this.http
      .delete(`${environment.apiUrl}/events/${eventId}/user/${userId}`)
      .toPromise();
  }

  addCompetitionToEvent(eventId: String, addCategory: AddCategory) {
    return this.http
      .post(`${environment.apiUrl}/events/${eventId}/competition`, addCategory)
      .toPromise();
  }

  getCompetitionByEventAndDate(eventId: String, date: Number) {
    return this.http
      .get(`${environment.apiUrl}/competition/event/${eventId}/date/${date}`)
      .toPromise();
  }

  deleteCompetitionCategory(eventId: String, competitionId: String) {
    return this.http
      .delete(
        `${environment.apiUrl}/event/${eventId}/competition/${competitionId}`
      )
      .toPromise();
  }

  addTakeOff(takeOff: TakeOff, eventId: String) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/takeoff`, takeOff)
      .toPromise();
  }

  getTakeOffs(eventId: String, competitionId: String) {
    return this.http
      .get(
        `${environment.apiUrl}/event/${eventId}/takeoff/competition/${competitionId}`
      )
      .toPromise();
  }

  getEveryTakeOffs(eventId: String, competitionId: String) {
    return this.http
      .get(
        `${environment.apiUrl}/event/${eventId}/takeoff/everycompetition/${competitionId}`
      )
      .toPromise();
  }

  getCustomerTakeoffs(eventId: String, customerId: String) {
    return this.http
      .get(
        `${environment.apiUrl}/event/${eventId}/takeoff/customer/${customerId}`
      )
      .toPromise();
  }

  deleteTakeOff(
    eventId: String,
    subscriptionId: String,
    competitionId: String
  ) {
    return this.http
      .delete(
        `${environment.apiUrl}/event/${eventId}/competition/${competitionId}/takeoff/${subscriptionId}`
      )
      .toPromise();
  }

  changeTakeoff(
    eventId: String,
    takeoffId: String,
    ownerId: String,
    competitorId: String,
    horseId: String
  ) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/takeoff/change`, {
        ownerId,
        competitorId,
        horseId,
        takeoffId,
      })
      .toPromise();
  }

  getCompetition(competitionId: String) {
    return this.http
      .get(`${environment.apiUrl}/competition/${competitionId}`)
      .toPromise();
  }

  getEventCompetitions(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/competition/event/${eventId}`)
      .toPromise();
  }

  getEventAllCompetitions(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/competition/all/event/${eventId}`)
      .toPromise();
  }

  getEventCompetitionsPass(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/competition/pass/event/${eventId}`)
      .toPromise();
  }

  getEventIsRankCompetitions(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/competition/event-isrank/${eventId}`)
      .toPromise();
  }

  getEventIsNoRankCompetitions(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/competition/event-isnorank/${eventId}`)
      .toPromise();
  }

  getEventResults(eventId: String) {
    return this.http
      .get(`${environment.apiUrl}/competition/results/event/${eventId}`)
      .toPromise();
  }
  getEventResultsTakeoffs(eventId: String, competitionId: string) {
    return this.http
      .get(
        `${environment.apiUrl}/event/${eventId}/competition/results/${competitionId}/takeoffs`
      )
      .toPromise();
  }

  setEventCompetitionOrder(eventId: string, orderList) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/competitions/order`, {
        orderList,
      })
      .toPromise();
  }

  addAnimal(animal: Animal) {
    return this.http.post(`${environment.apiUrl}/horse`, animal).toPromise();
  }

  editAnimal(animal: Animal) {
    return this.http.put(`${environment.apiUrl}/horse`, animal).toPromise();
  }

  editAnimalChip(_id: string, chip: string) {
    return this.http
      .put(`${environment.apiUrl}/horse/chip`, { chip, _id })
      .toPromise();
  }

  getAllAnimals(page: number) {
    return this.http
      .get(`${environment.apiUrl}/horses?page=${page}`)
      .toPromise();
  }

  addCompetitor(competitor: Competitor) {
    return this.http
      .post(`${environment.apiUrl}/competitor`, competitor)
      .toPromise();
  }

  editCompetitor(competitor: Competitor) {
    return this.http
      .put(`${environment.apiUrl}/competitor`, competitor)
      .toPromise();
  }

  getAllCompetitors(page: number) {
    return this.http
      .get(`${environment.apiUrl}/competitors?page=${page}`)
      .toPromise();
  }

  setCompetitionIsActiveSubscription(
    eventId: string,
    competitionId: string,
    isActiveSubscriptions
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/competition/activesubscription`,
        { eventId, competitionId, isActiveSubscriptions }
      )
      .toPromise();
  }

  setCompetitionIsActiveSubscriptionAll(
    eventId: string,
    isActiveSubscriptions
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/competition/activesubscriptionall`,
        { eventId, isActiveSubscriptions }
      )
      .toPromise();
  }

  setCompetitionIsActiveSubscriptionSchedule(eventId: string, date: string) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/competition/activesubscriptionschedule`,
        { date }
      )
      .toPromise();
  }

  getScheduleSubscriptionActive(eventId: string) {
    return this.http
      .get(
        `${environment.apiUrl}/event/${eventId}/competition/activesubscriptionschedule`
      )
      .toPromise();
  }

  setCompetitionResult(
    eventId: string,
    competitionId: string,
    type: string,
    classifiedAmount: number,
    classifiedAmountByDivision: number,
    divisionRange: Array<number>
  ) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/competition/results`, {
        competitionId,
        type,
        classifiedAmount,
        classifiedAmountByDivision,
        divisionRange,
      })
      .toPromise();
  }

  setCompetitionHours(
    eventId: string,
    competitionId: string,
    hours: Array<any>
  ) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/competition/hours`, {
        competitionId,
        hours,
      })
      .toPromise();
  }

  setCompetitionAwards(
    eventId: string,
    competitionId: string,
    awardPercentage: string,
    awardPerClassified: string,
    awardPerClassifiedDivision: string,
    awardType: string
  ) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/competition/awards`, {
        competitionId,
        awardPercentage,
        awardPerClassified,
        awardPerClassifiedDivision,
        awardType,
      })
      .toPromise();
  }

  setCompetitionRules(
    eventId: string,
    competitionId: string,
    maxAgeAnimal: number,
    minAgeAnimal: number,
    maxAgeCompetitor: number,
    minAgeCompetitor: number,
    subscriptionLimit: number,
    genderRule: string,
    skillLevel: string,
    closedDivision: boolean,
    subscriptionPerCompetitorLimit,
    stallionAndFemalePerCompetitorSubscriptionLimit,
    allowStallion,
    maxCompetitorScore: number,
    minCompetitorScore: number,
    isLineage: boolean,
    onlyOneSubscriptionPerCompetitor: boolean
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/competition/${competitionId}/rules`,
        {
          maxAgeAnimal,
          minAgeAnimal,
          maxAgeCompetitor,
          minAgeCompetitor,
          subscriptionLimit,
          genderRule,
          skillLevel,
          closedDivision,
          subscriptionPerCompetitorLimit,
          stallionAndFemalePerCompetitorSubscriptionLimit,
          allowStallion,
          maxCompetitorScore,
          minCompetitorScore,
          isLineage,
          onlyOneSubscriptionPerCompetitor,
        }
      )
      .toPromise();
  }

  setCompetitionSettings(
    eventId: string,
    competitionId: string,
    rankOnlyOne: boolean,
    rankCompetition: string,
    rankedToCompetition: string,
    sumCompetition: string,
    rankType: string,
    selectedClassificatoryType: string,
    averagePrice: number,
    paymentPrice: number,
    limitScoreSAT: string,
    alwaysSubscriptionAllPass: boolean
  ) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/competition/${competitionId}/settings`,
        {
          rankOnlyOne,
          rankCompetition,
          rankedToCompetition,
          sumCompetition,
          rankType,
          selectedClassificatoryType,
          averagePrice,
          paymentPrice,
          limitScoreSAT,
          alwaysSubscriptionAllPass,
        }
      )
      .toPromise();
  }

  setCompetitionAbqm(eventId: string, competitionId: string, abqmId: boolean) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/competition/${competitionId}/abqm`,
        {
          abqmId,
        }
      )
      .toPromise();
  }

  addEventStall(
    stallType: string,
    price: number,
    limitStalls: number,
    eventId: string,
    limitStallsGlobal: number,
    limitToCancel: number
  ) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/stall`, {
        stallType,
        price,
        limitStalls,
        eventId,
        limitStallsGlobal,
        limitToCancel,
      })
      .toPromise();
  }

  setStallActive(eventId: string, stallId: String, isActive: boolean) {
    return this.http
      .put(`${environment.apiUrl}/event/${eventId}/stall/${stallId}/active`, {
        isActive,
      })
      .toPromise();
  }

  setStallIsActiveSchedule(eventId: string, stallId: String, date: String) {
    return this.http
      .post(
        `${environment.apiUrl}/event/${eventId}/stall/activestallschedule`,
        {
          stallId,
          date,
        }
      )
      .toPromise();
  }

  getScheduleStallActive(eventId: string, stallId: String) {
    return this.http
      .get(
        `${environment.apiUrl}/event/${eventId}/stall/${stallId}/activestallschedule`
      )
      .toPromise();
  }

  deleteEventCustomerStall(eventId, customerStallId) {
    return this.http
      .delete(
        `${environment.apiUrl}/event/${eventId}/customerstall/${customerStallId}`
      )
      .toPromise();
  }

  getEventStalls(eventId: string) {
    return this.http
      .get(`${environment.apiUrl}/event/${eventId}/stalls`)
      .toPromise();
  }

  addEventCoupon(
    event,
    code,
    value,
    isPercentage,
    startDate,
    finalDate,
    competitions
  ) {
    return this.http
      .post(`${environment.apiUrl}/event/${event}/coupon`, {
        event,
        code,
        value,
        isPercentage,
        startDate,
        finalDate,
        competitions,
      })
      .toPromise();
  }

  updateEventAutomaticCoupon(event, coupon, isCouponActive) {
    return this.http
      .put(`${environment.apiUrl}/event/${event}/automaticcoupon`, {
        coupon,
        isCouponActive,
      })
      .toPromise();
  }

  deleteEventCoupon(eventId, couponId) {
    return this.http
      .delete(`${environment.apiUrl}/event/${eventId}/coupon/${couponId}`)
      .toPromise();
  }

  getEventCoupons(eventId: string) {
    return this.http
      .get(`${environment.apiUrl}/event/${eventId}/coupons`)
      .toPromise();
  }

  getStall(stallId: string) {
    return this.http.get(`${environment.apiUrl}/stall/${stallId}`).toPromise();
  }

  deleteEventStall(eventId: string, stallId: string) {
    return this.http
      .delete(`${environment.apiUrl}/event/${eventId}/stall/${stallId}`)
      .toPromise();
  }

  getAllCustomerStalls(eventId: string, stallId: string) {
    return this.http
      .get(`${environment.apiUrl}/event/${eventId}/stall/${stallId}`)
      .toPromise();
  }

  addCustomerStall(
    customer: string,
    eventId: string,

    stall: string,
    coach: string,
    horse: string
  ) {
    return this.http
      .post(`${environment.apiUrl}/event/${eventId}/customerstall`, {
        customer,
        stall,
        coach,
        horse,
      })
      .toPromise();
  }
  //remove in future

  getEventLiveCompetitions(eventId: string) {
    return this.http
      .get(`${environment.apiUrl}/event/${eventId}/live/competitions`)
      .toPromise();
  }

  getEventLiveCompetitionTakeOffs(competitionId: string) {
    return this.http
      .get(`${environment.apiUrl}/event/live/${competitionId}/takeoffs`)
      .toPromise();
  }

  editScore(_id: string, score: string) {
    return this.http
      .put(`${environment.apiUrl}/horse/score`, { score, _id })
      .toPromise();
  }

  getActivityRegistry(eventId: string, page: number, action: string) {
    return this.http
      .get(
        `${environment.apiUrl}/activity-register/${eventId}?page=${page}&action=${action}`
      )
      .toPromise();
  }

  addCompetitorAbqm(cpf: string) {
    return this.http
      .post(`${environment.apiUrl}/abqm/competitor`, {
        cpf,
      })
      .toPromise();
  }

  addHorseAbqm(animalId: string) {
    return this.http
      .post(`${environment.apiUrl}/abqm/horse`, {
        animalId,
      })
      .toPromise();
  }

  getEventHorseTakeoffs(eventId: string, horseId: string) {
    return this.http
      .get(`${environment.apiUrl}/events/${eventId}/horse/${horseId}/takeoffs`)
      .toPromise();
  }

  validateTakeoff(
    eventId: string,
    competitorId: string,
    horseId: string,
    competitorCpf: string,
    animalId: string,
    isCompetitorCore: boolean,
    isHorseCore: boolean,
    emptyCpf: boolean
  ) {
    return this.http
      .get(`${environment.apiUrl}/event/${eventId}/validate-takeoff`, {
        params: {
          competitorId,
          horseId,
          competitorCpf,
          animalId,
          isCompetitorCore,
          isHorseCore,
          emptyCpf,
        },
      })
      .toPromise();
  }
}
