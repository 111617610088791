import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertTranslateService } from 'src/app/services/alert.service';
import { EventService } from 'src/app/services/event.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-event-date-edit',
  templateUrl: './event-date-edit.component.html',
  styleUrls: ['./event-date-edit.component.scss'],
  providers: [DatePipe]
})
export class EventDateEditComponent implements OnInit {
  editEventDateForm: FormGroup;
  submitted: boolean = false;
  @Input() event: any;
  eventDaysCount: number = 0;
  
  // Getter para facilitar o acesso ao FormArray
  get eventDatesFormArray(): FormArray {
    return this.editEventDateForm.get('eventDates') as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertTranslateService,
    private _event: EventService,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    // Calcular a quantidade atual de dias do evento
    this.eventDaysCount = this.event.eventDays ? this.event.eventDays.length : 1;
    
    // Criar o FormGroup principal
    this.editEventDateForm = this.formBuilder.group({
      eventDates: this.formBuilder.array([])
    }, { validator: this.datesSequentialValidator });
    
    // Inicializar o FormArray com um FormGroup para cada dia do evento
    this.initEventDatesFormArray();
  }

  // Inicializar o FormArray com controles para cada dia do evento
  initEventDatesFormArray() {
    const eventDatesArray = this.eventDatesFormArray;
    
    // Limpar o array existente
    while (eventDatesArray.length) {
      eventDatesArray.removeAt(0);
    }
    
    // Adicionar um FormGroup para cada dia do evento
    if (this.event.eventDays && this.event.eventDays.length > 0) {
      // Se o evento já tem dias definidos, usar as datas existentes
      this.event.eventDays.forEach((dayTimestamp: number) => {
        eventDatesArray.push(
          this.formBuilder.group({
            date: [this.formatDateForInput(dayTimestamp), [Validators.required, this.pastDateValidator]]
          })
        );
      });
    } else {
      // Se não tem dias definidos, criar controles vazios
      for (let i = 0; i < this.eventDaysCount; i++) {
        eventDatesArray.push(
          this.formBuilder.group({
            date: ['', [Validators.required, this.pastDateValidator]]
          })
        );
      }
    }
  }
  
  // Validador para garantir que a data não esteja no passado
  pastDateValidator(control: AbstractControl) {
    if (!control.value) {
      return null;
    }
    
    const selectedDate = new Date(control.value);
    selectedDate.setHours(0, 0, 0, 0);
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    if (selectedDate < today) {
      return { pastDate: true };
    }
    
    return null;
  }
  
  // Validador para garantir que as datas estejam em sequência
  datesSequentialValidator(group: FormGroup) {
    const eventDatesArray = group.get('eventDates') as FormArray;
    
    if (!eventDatesArray || eventDatesArray.length < 2) {
      return null;
    }
    
    const dates: Date[] = [];
    
    // Coletar todas as datas válidas
    for (let i = 0; i < eventDatesArray.length; i++) {
      const dateControl = eventDatesArray.at(i).get('date');
      if (dateControl && dateControl.value) {
        const date = new Date(dateControl.value);
        date.setHours(0, 0, 0, 0);
        dates.push(date);
      }
    }
    
    // Verificar se as datas estão em sequência
    for (let i = 1; i < dates.length; i++) {
      const prevDate = new Date(dates[i-1]);
      prevDate.setDate(prevDate.getDate() + 1);
      
      if (dates[i].getTime() !== prevDate.getTime()) {
        return { datesNotSequential: true };
      }
    }
    
    return null;
  }

  // Formatar timestamp para formato de data para o input
  private formatDateForInput(timestamp: number): string {
    // Converte o timestamp para uma data local
    const date = new Date(timestamp);
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.editEventDateForm.valid) return;

    try {
      // Obter os valores de data do FormArray
      const eventDates = this.eventDatesFormArray.controls.map(control => {
        const dateValue = control.get('date').value;
        const [year, month, day] = dateValue.split('-').map(Number);
        
        // Criar um objeto Date para o início do dia (00:00:00)
        const startOfDay = new Date(
          year,
          month - 1, // Meses em JavaScript são 0-indexed (janeiro = 0)
          day,
          0, 0, 0, 0
        ).getTime();
        
        return startOfDay;
      });
      
      // Calcular startDate e finalDate a partir dos eventDates
      const startDate = eventDates[0];
      
      // Para a data final, usamos o último dia às 23:59:59.999
      const lastDayDate = new Date(eventDates[eventDates.length - 1]);
      const finalDate = new Date(
        lastDayDate.getFullYear(),
        lastDayDate.getMonth(),
        lastDayDate.getDate(),
        23, 59, 59, 999
      ).getTime();
      
      // Enviar para o backend
      await this._event.updateEventDates(
        this.event._id,
        startDate,
        finalDate,
        eventDates // Enviar o array completo de datas
      );
      
      this.alertService.success('EVENT-DATES-UPDATED');
      this.submitted = false;
      this.activeModal.close('updated');
    } catch (ex) {
      this.alertService.danger('ERROR-UPDATE-EVENT-DATES');
      this.submitted = false;
    }
  }

  get f() {
    return this.editEventDateForm.controls;
  }
}
